import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "leads-tasks-filter-v2" }
const _hoisted_2 = { class: "card border-light health-leads-tasks" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = { class: "card border-light mt-5" }
const _hoisted_6 = { class: "card-body card-custom-spacing-tblf-10" }
const _hoisted_7 = { class: "card-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_customer_first_layer = _resolveComponent("vc-customer-first-layer")!
  const _component_vc_customer_second_layer = _resolveComponent("vc-customer-second-layer")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_vc_customer_third_layer = _resolveComponent("vc-customer-third-layer")!
  const _component_vc_customer_fourth_layer = _resolveComponent("vc-customer-fourth-layer")!
  const _component_vc_customer_table_v2 = _resolveComponent("vc-customer-table-v2")!
  const _component_vc_customer_last_layer = _resolveComponent("vc-customer-last-layer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_vc_customer_first_layer),
          (_ctx.showHide.leadsAndTasksLeadCountFilter)
            ? (_openBlock(), _createBlock(_component_vc_customer_second_layer, {
                key: 0,
                class: "mt-5"
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_el_divider),
          _createVNode(_component_vc_customer_third_layer, { class: "mt-5" }),
          _createVNode(_component_el_divider, { style: {"border-top":"2px solid #dcdfe6"} }),
          _createVNode(_component_vc_customer_fourth_layer)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_vc_customer_table_v2)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_vc_customer_last_layer)
      ])
    ])
  ]))
}